import React from 'react';
import { Box, Typography, Card, CardContent, Stack } from '@mui/material';

const HowToBuy: React.FC = () => {
  const steps = [
    {
      step: 1,
      title: "Set Up a Wallet",
      description:
        "Download a crypto wallet like Phantom wallet. Don’t forget to write down your seed phrase—it’s like your pineapple passport. 🍍",
    },
    {
      step: 2,
      title: "Add Binance Smart Chain",
      description:
        "Switch your wallet to Binance Smart Chain (BSC). Google it if you’re lost—it’s easier than peeling a pineapple. 🌴",
    },
    {
      step: 3,
      title: "Buy Solana",
      description:
        "Purchase Solana (SOL) from an exchange like Binance, and transfer it to your wallet. Fuel for your pineapple journey! 🚀",
    },
    {
      step: 4,
      title: "Swap for PINE",
      description:
        "Click BUY PINE on the top of a website, it will redirect you to dex screener where you can connect you wallet and swap SOL for PINE. Watch your pineapple empire grow. 🍹",
    },
  ];

  return (
    <Box
      style={{
        padding: '50px 20px',
        backgroundColor: '#251832',
        color: '#fff',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h4"
        style={{
          marginBottom: '40px',
          fontWeight: 'bold',
          fontSize: '2.5rem',
        }}
      >
        How to Buy PINE 🍍
      </Typography>
      <Stack spacing={4} alignItems="center">
        {steps.map((step) => (
          <Card
            key={step.step}
            style={{
              width: '800px',
              height: '140px',
              backgroundColor: '#251832',
              color: '#fff',
              border: '1px solid #ffcc00',
              borderRadius: '8px',
            }}
            data-aos="fade-up"
          >
            <CardContent>
              <Typography
                variant="h5"
                style={{
                  fontWeight: 'bold',
                  color: '#ffcc00',
                  marginBottom: '10px',
                }}
              >
                Step {step.step}: {step.title}
              </Typography>
              <Typography variant="body1">{step.description}</Typography>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </Box>
  );
};

export default HowToBuy;
