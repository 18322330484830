import React from 'react';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';
import RedditIcon from '@mui/icons-material/Reddit';
const Community: React.FC = () => {
  return (
    <Box 
      id="community"
      style={{
        padding: '50px 20px',
        backgroundColor: '#251832',
        color: '#fff',
        textAlign: 'center',
      }}
      data-aos="fade-up"
    >
      <Typography variant="h4" style={{ marginBottom: '20px', fontWeight: 'bold' }}>
        Join Our Community
      </Typography>
      <Typography
        variant="body1"
        style={{
          paddingBottom: '32px',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        Be part of the Pineapple Invasion army! Follow us on social media and join the conversation.
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        <Grid item>
          <IconButton
            component="a"
            href="https://x.com/PINEStonks" // Заменить на реальную ссылку
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#1DA1F2',
              backgroundColor: '#fff',
              padding: '15px',
              borderRadius: '50%',
            }}
          >
            <XIcon />
          </IconButton>
          <Typography variant="body1" style={{ marginTop: '10px', color: '#fff' }}>
            Twitter
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            component="a"
            href="https://t.me/+T6uiIai7g7thZDY6" // Заменить на реальную ссылку
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#0088cc',
              backgroundColor: '#fff',
              padding: '15px',
              borderRadius: '50%',
            }}
          >
            <TelegramIcon />
          </IconButton>
          <Typography variant="body1" style={{ marginTop: '10px', color: '#fff' }}>
            Telegram
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Community;
