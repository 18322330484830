import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box
      style={{
        backgroundColor: '#251832',
        color: '#fff',
        padding: '20px 0',
        textAlign: 'center',
      }}
    >
      <Typography variant="body2" style={{ marginBottom: '10px' }}>
        © {new Date().getFullYear()} Pineapple Invasion. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
