import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';

const Header: React.FC = () => {
  return (
    <AppBar position="static" style={{ backgroundColor: 'rgba(37, 24, 50, 0.8)' }}>
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          
        </Typography>
        <Button 
            color="inherit" 
            style={{ 
                marginRight: '8px',
                transition: 'background-color 0.3s, color 0.3s',
            }}
            onMouseOver={(e) => {
                e.currentTarget.style.color = '#ffcc00'; // Меняем текст на жёлтый
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.color = 'white'; // Возвращаем чёрный текст
              }}
              href="#about-token">About</Button>

        <Button 
            color="inherit" 
            style={{ 
                marginRight: '8px',
                transition: 'background-color 0.3s, color 0.3s',
            }}
            onMouseOver={(e) => {
                e.currentTarget.style.color = '#ffcc00'; // Меняем текст на жёлтый
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.color = 'white'; // Возвращаем чёрный текст
              }}
            href="#roadmap">Roadmap</Button>
            
        <Button 
            color="inherit" 
            style={{ 
                marginRight: '32px',
                transition: 'background-color 0.3s, color 0.3s',
            }}
            onMouseOver={(e) => {
                e.currentTarget.style.color = '#ffcc00'; // Меняем текст на жёлтый
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.color = 'white'; // Возвращаем чёрный текст
              }}
            href="#community">Community</Button>
        <Button 
            variant="contained" 
            style={{
                backgroundColor: '#ffcc00',
                color: '#000',
                fontWeight: 'bold',
                transition: 'transform 0.3s, background-color 0.3s',
              }}
            onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
            onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
            data-aos="zoom-in"
            href="https://dexscreener.com/moonshot" // Ссылка на PancakeSwap или другой сервис
            target="_blank"
            rel="noopener noreferrer"
            >
          Buy PINE
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
