import React from 'react';
import { Box, Typography, Grid2, List, ListItem, ListItemText, Card, CardContent } from '@mui/material';

const AboutToken: React.FC = () => {
    return (
        <Box
            id="about-token"
            style={{
                marginTop: '32px',
                padding: '50px 20px',
                background: 'linear-gradient(180deg, rgba(37, 24, 50, 0.0), rgba(31, 19, 42, 1) 10%, #1f132a 100%)',
                color: '#fff',
                textAlign: 'center',
            }}
            data-aos="fade-up" // Анимация для всей секции
        >
            {/* Key Features */}
            <Grid2 container spacing={4} justifyContent="center" paddingBottom='40px'>
                <Grid2>
                    <Card
                        style={{
                            backgroundColor: 'rgba(37, 24, 50, 0.5)',
                            width: '320px',
                            height: '140px',
                            color: '#fff',
                            border: '1px solid #ffcc00',
                            borderRadius: '8px',
                            marginTop: '32px'
                        }}
                        data-aos="flip-left"
                    >
                        <CardContent>
                            <Typography variant="h6" style={{ fontWeight: 'bold', color: '#ffcc00' }}>
                                💥 Nice supply.
                            </Typography>
                            <Typography variant="body1">
                                Only 420,690,000,000 PINE in circulation. We’re people of culture after all. 😎
                            </Typography>
                        </CardContent>
                    </Card>

                </Grid2>
                <Grid2>
                    <Card
                        style={{
                            backgroundColor: 'rgba(37, 24, 50, 0.5)',
                            width: '320px',
                            height: '140px',
                            color: '#fff',
                            border: '1px solid #ffcc00',
                            borderRadius: '8px',
                            marginTop: '32px'
                        }}
                        data-aos="flip-left"
                    >
                        <CardContent>
                            <Typography variant="h6" style={{ fontWeight: 'bold', color: '#ffcc00' }}>
                                👾 Zero Tax — No Nonsense
                            </Typography>
                            <Typography variant="body1">
                                No hidden fees, no surprises. 😎
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid2>
                <Grid2>
                    <Card
                        style={{
                            backgroundColor: 'rgba(37, 24, 50, 0.5)',
                            width: '320px',
                            height: '140px',
                            color: '#fff',
                            border: '1px solid #ffcc00',
                            borderRadius: '8px',
                            marginTop: '32px'
                        }}
                        data-aos="flip-left"
                    >
                        <CardContent>
                            <Typography variant="h6" style={{ fontWeight: 'bold', color: '#ffcc00' }}>
                                🍹 Renounced contract
                            </Typography>
                            <Typography variant="body1">
                                We yeeted it into the blockchain abyss. Good luck out there!
                            </Typography>
                        </CardContent>
                    </Card>

                </Grid2>
            </Grid2>

            {/* Title */}
            <Typography
                variant="h4"
                style={{
                    marginBottom: '20px',
                    fontWeight: 'bold',
                    fontSize: '2.5rem',
                }}
            >
                Pineapple Invasion (PINE): The Token That Rules Them All
            </Typography>

            {/* Intro */}
            <Typography
                variant="body1"
                style={{
                    paddingBottom: '40px',
                    maxWidth: '800px',
                    margin: '0 auto',
                    fontSize: '1.2rem',
                    lineHeight: '1.8',
                }}
            >
                The fruit wars have begun. The bananas thought they stood a chance. The apples thought
                they were the teacher’s favorite. But none of them were ready for the absolute chaos that
                is Pineapple Invasion. This isn’t just a token. It’s a movement. It’s a revolution. It’s an
                existential crisis wrapped in a spiky tropical skin. 🍍🚀
            </Typography>

            {/* Why Pineapples */}
            <Typography
                variant="h5"
                style={{
                    paddingBottom: '20px',
                    fontWeight: 'bold',
                    fontSize: '2rem',
                }}
            >
                Why Pineapples?
            </Typography>
            <Box
                style={{
                    marginTop: '40px',
                    padding: '20px',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: '10px',
                    width: '760px',
                    margin: '40px auto 0',
                }}
            >
                <List
                    style={{
                        maxWidth: '800px',
                        margin: '0 auto',
                        color: '#fff',
                        fontSize: '1.2rem',
                        lineHeight: '1.8',
                    }}
                >
                    <ListItem>
                        <ListItemText primary="🍍 Because they’re badass. They wear crowns and don’t apologize for it. 👑" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="🍍 Because they’re sweet and dangerous. A pineapple will make your taste buds dance, but try to peel one without gloves, and you’ll know true pain." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="🍍 Because they’re chaotic-neutral. Pineapple on pizza? Some call it blasphemy. We call it courage.🍕" />
                    </ListItem>
                </List>
                <Typography
                    variant="body1"
                    style={{
                        marginTop: '20px',
                        fontSize: '1.2rem',
                        fontStyle: 'italic',
                        color: '#ffcc00',
                    }}
                >
                    “Once we land on the moon, the only thing rising faster than your PINE will be the demand for that juice.” 🍹🍍🚀
                </Typography>
            </Box>

            

            {/* Mission Statement */}
            <Typography
                variant="h5"
                style={{
                    marginTop: '40px',
                    marginBottom: '20px',
                    fontWeight: 'bold',
                    fontSize: '2rem',
                }}
            >
                Mission Statement
            </Typography>
            <Box
                style={{
                    marginTop: '40px',
                    padding: '20px',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: '10px',
                    width: '760px',
                    margin: '40px auto 0',
                }}
            >
                <Typography
                    variant="body1"
                    style={{
                        marginBottom: '20px',
                        maxWidth: '800px',
                        margin: '0 auto',
                        fontSize: '1.2rem',
                        lineHeight: '1.8',
                    }}
                >
                    Pineapple Invasion exists to do three things:
                    <List>
                        <ListItem>
                            <ListItemText primary="🍍 Dominate the blockchain. Bananas are crying. Apples are trembling. Even oranges are reconsidering their choices in life." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="🍍 Create laughter. A world without memes is a world not worth living in. Our memes are sharp, just like pineapple leaves." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="🍍 Prove that pineapple belongs on pizza. We’re not just a token. We’re a cause. A spicy, juicy, cheesy cause." />
                        </ListItem>
                    </List>
                </Typography>
            </Box>
        </Box>
    );
};

export default AboutToken;
