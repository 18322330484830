import React from 'react';
import { List, ListItem, ListItemText, Box, Typography, Card, CardContent, Stack } from '@mui/material';

const roadmapData = [
  {
    phase: "Phase 1",
    title: "The Plantation 🌱",
    detail1: "🍍 Launch PINE. Renounce the contract.",
    detail2: "🍍 Start the Pineapple Pizza Debate (we win, obviously)",
  },
  {
    phase: "Phase 2",
    title: "The Great Fruit Wars 🥊",
    detail1: "🍍 Get listed on Raydium and other exchanges.",
    detail2: "🍍 Grow the wildest Pineapple Army the blockchain has ever seen.",
  },
  {
    phase: "Phase 3",
    title: "To the Moon 🌙",
    detail1: "🍍 Dominate CoinGecko and CoinMarketCap rankings.",
    detail2: "🍍 Turn Pineapple Invasion into a viral sensation.",
  },
  {
    phase: "Phase 4",
    title: "The Empire 👑",
    detail1: "🍍 Launch a pineapple into space (why not?).",
    detail2: "🍍 Prepare for world domination. Whatever the Pineapple Army memes into reality. 🍍",
  },
];

const Roadmap: React.FC = () => {
  return (
    <Box
      id="roadmap"
      style={{
        padding: '20px 20px',
        background: 'linear-gradient(180deg, #1f132a, #251832)',
        color: '#fff',
        textAlign: 'center',
      }}
      data-aos="fade-up"
    >
      <Typography variant="h4" style={{ marginBottom: '32px', fontWeight: 'bold' }}>
        Pineapple Invasion Roadmap
      </Typography>
      <Typography
        variant="body1"
        style={{ paddingBottom: '32px', maxWidth: '800px', margin: '0 auto' }}
      >
        From chaos to crypto domination, one meme at a time.
      </Typography>
      <Stack spacing={4} alignItems="center">
        {roadmapData.map((item, index) => (
          <Card
            key={index}
            style={{
              width: '800px',
              height: '260px',
              backgroundColor: '#251832',
              color: '#fff',
              border: '1px solid #ffcc00',
              borderRadius: '8px',
            }}
            data-aos="flip-left"
          >
            <CardContent>
              <Typography variant="h6" style={{ color: '#ffcc00', fontWeight: 'bold' }}>
                {item.phase}
              </Typography>
              <Typography variant="h5" style={{ margin: '10px 0' }}>
                {item.title}
              </Typography>
              <List>
                <ListItem>
                  <ListItemText primary={item.detail1} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={item.detail2} />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </Box>
  );
};

export default Roadmap;
