import React, { useEffect } from 'react';
import 'aos/dist/aos.css'; // Импорт CSS для анимаций
import AOS from 'aos';
import bannerImage from './assets/images/banner.png'; // Путь к твоему изображению
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AboutToken from './components/AboutToken';
import Roadmap from './components/Roadmap';
import HowToBuy from './components/HowToBuy';
import Community from './components/Community';
import Footer from './components/Footer';

const App: React.FC = () => {
    useEffect(() => {
        document.body.style.backgroundImage = `
          linear-gradient(
            rgba(37, 24, 50, 0.2), 
            rgba(37, 24, 50, 0.5)
          ),
          url(${bannerImage})
        `;
        document.body.style.backgroundPosition = 'top';
        document.body.style.backgroundSize = '100% auto';
        document.body.style.backgroundRepeat = 'no-repeat';
        document.body.style.backgroundColor = '#251832';
        document.body.style.margin = '0';
        document.body.style.color = '#fff';
        document.body.style.overflowX = 'hidden';
      
        AOS.init({
          duration: 1000,
          once: true,
        });
      }, []);

  return (
    <div>
      <Header />
      <HeroSection />
      <AboutToken />
      <Roadmap />
      <HowToBuy/>
      <Community />
      <Footer />
    </div>
  );
};

export default App;
