import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import bannerImage from '../assets/images/banner.png'; // Путь к твоему изображению
import { Height } from '@mui/icons-material';

const HeroSection: React.FC = () => {
  return (
    <Box
      id="hero-section"
      style={{
        position: 'relative',
        width: '100%',
        height: '900px',
        display: 'flex',
        flexDirection: 'column', // Элементы располагаются по вертикали
        justifyContent: 'flex-end', // Сдвигаем элементы вниз
        alignItems: 'center', // Центрируем элементы по горизонтали
        paddingBottom: '20px', // Отступ от нижнего края
        textAlign: 'center',
        color: 'white',
      }}
    >


      {/* Контент */}
      <Box>
        <Typography 
            variant="h1" 
            style={{
                textShadow: '4px 4px 20px rgba(0, 0, 0, 0.8)',
              }}
            data-aos="fade-left" // Анимация появления сверху
        >
          Join the Invasion!
        </Typography>
        <Typography 
            variant="h3" 
            style={{ 
                marginBottom: '40px',
                textShadow: '4px 4px 20px rgba(0, 0, 0, 0.8)', 

            }}
            data-aos="fade-right" 
            >
          Pineapples are taking over the blockchain
        </Typography>
        <Button 
            variant="contained" 
            style={{
                backgroundColor: '#ffcc00',
                color: '#000',
                marginRight: '72px',
                fontWeight: 'bold',
                scale: '1.5',
                transition: 'transform 0.3s, background-color 0.3s',
              }}
            onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
            onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
            data-aos="zoom-in"
            href="https://dexscreener.com/moonshot" // Ссылка на PancakeSwap или другой сервис
            target="_blank"
            rel="noopener noreferrer"
            >
          Buy PINE
        </Button>
        <Button 
            variant="outlined" 
            style={{
                borderColor: '#ffcc00',
                color: '#ffcc00',
                fontWeight: 'bold',
                scale: '1.5',
                transition: 'transform 0.3s, background-color 0.3s',
              }}
            onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
              onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}  
              href="#about-token"
            data-aos="zoom-in"
              >
          Learn More
        </Button>
      </Box>
    </Box>
  );
};

export default HeroSection;
